<template>
  <th-page-wrapper>
    <th-datatable
      ref="table"
      v-loading="loading"
      do-route
      do-route-filters
      no-meta-check
      resource="productGroups"
      route-base="/products/product_groups"
      show-search-filter
      sortable
      :search-filters="filtersList"
      :buttons="computedButtons"
      :headers="headers"
      :operations="operations"
      :resource-query="{ query: { embed: ['tax', 'account'], deleted: false } }"
      :show-operations="false"
      @loading-error="handleLoadingError"
    >
      <template #dropdown-items-start>
        <template-file-button
          href="https://storage.googleapis.com/tillhub-dashboard/templates/vorlage_warengruppenliste.xlsx"
        />
        <product-groups-importer
          v-if="$checkPermissions({ scopes: ['product_groups:create'] })"
          :resources="resources"
          @refresh="refresh"
        />
      </template>
    </th-datatable>
  </th-page-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import typeOf from 'just-typeof'
import ProductGroupsImporter from './components/product-groups-importer'
import TemplateFileButton from '@components/importer/template-file-button'

export default {
  metaInfo() {
    return {
      title: this.$t('common.resource.product_group.plural')
    }
  },
  components: {
    ProductGroupsImporter,
    TemplateFileButton
  },
  data() {
    return {
      loading: false,
      resources: {},
      permissions: {
        create: {
          scopes: ['products:create']
        },
        edit: {
          scopes: ['products:update']
        },
        delete: {
          scopes: ['products:delete']
        }
      },
      headers: [
        {
          field: 'name',
          label: this.$t('pages.product_groups.all.headers.name'),
          fallback: '-',
          truncate: true,
          minWidth: 120
        },
        {
          field: 'product_group_id',
          label: this.$t('pages.product_groups.all.headers.product_group_id'),
          minWidth: 140,
          sortType: 'number',
          fallback: '-'
        },
        {
          field: 'account',
          label: this.$t('pages.product_groups.all.headers.account'),
          fallback: '-',
          truncate: true,
          minWidth: 160,
          formatter: (row, column) => {
            if (!row || !row.account || !row.account.name) return '-'

            return row.account.name
          }
        },
        {
          field: 'tax',
          label: this.$t('pages.product_groups.all.headers.tax'),
          fallback: '-',
          truncate: true,
          minWidth: 120,
          formatter: (row, column) => {
            if (!row || !row.tax || !row.tax.name) return '-'

            return row.tax.name
          }
        }
      ],
      filtersList: [
        {
          name: 'active',
          type: 'select',
          label: this.$t('pages.products.all.filters.active.title'),
          options: [
            {
              label: this.$t(
                'pages.product_groups.all.filters.active.all_active'
              ),
              value: 'true'
            },
            {
              label: this.$t(
                'pages.product_groups.all.filters.active.all_inactive'
              ),
              value: 'false'
            }
          ]
        }
      ],
      buttons: [
        {
          type: 'create',
          scopes: ['products:product_groups:create']
        }
      ]
    }
  },
  computed: {
    operations() {
      const permissions =
        typeOf(this.permissions) === 'object' ? this.permissions : {}
      return Object.entries(permissions).reduce(
        (ops, [action = '', { scopes = [] }]) => {
          if (!action || !scopes || !scopes.length) return ops

          const permissionVal = this.$checkPermissions({ scopes })

          if (typeOf(permissionVal === 'boolean')) {
            ops[action] = permissionVal
          }

          return ops
        },
        {}
      )
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    }
  },
  created() {
    this.fetchResources()
  },
  methods: {
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.product_groups.title')
        })
      })
    },
    async fetchResources() {
      try {
        this.resources = await this.$resourceFetch(
          'taxes',
          {
            resource: 'accounts',
            handler: () =>
              th
                .accounts()
                .getAll({ query: { type: 'revenue', deleted: false } })
          },
          {
            resource: 'productGroups',
            // we specifically want to fetch also *deleted* product groups
            handler: () => th.productGroups().getAll()
          }
        )
      } catch (err) {
        this.$logException(err, { trackError: false })
      }
    },
    refresh() {
      this.$refs.table.refresh()
    }
  }
}
</script>

<style scoped></style>
