<template>
  <el-dropdown-item @click.stop="handleImport">
    <svgicon
      :src="require('@/assets/icons/th-icon-upload.svg')"
      :style="{ height: '20px', width: '20px' }"
      class="mr-2 fill-current"
    />
    <span>{{ $t('common.interactions.buttons.import') }}</span>
  </el-dropdown-item>
</template>

<script>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import th from '@tillhub/javascript-sdk'
import FlatfileImporter from '@flatfile/adapter'
import pRetry from 'p-retry'
import pLimit from 'p-limit'
import { mapState } from 'vuex'
import { i18nOverrides, createOptions, regexURLImage } from '@/utils/importer'
import { replaceEmptyPropsWithNull } from '@/utils/objects'

const FLATFILE_LICENSE_KEY = process.env.VUE_APP_FLATFILE_LICENSE_KEY

export default {
  name: 'CustomerImporter',
  props: {
    resources: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    //i18n
    const { t, tm } = useI18n()

    //computed
    const fields = computed(() => [
      {
        key: 'product_group_id',
        label: t('pages.product_groups.all.headers.product_group_id'),
        validators: [{ validate: 'required' }]
      },
      {
        key: 'name',
        label: t('common.headers.name.title'),
        validators: [{ validate: 'required' }]
      },
      {
        key: 'account',
        type: 'select',
        options: createOptions(props.resources?.accounts),
        label: t('pages.product_groups.all.headers.account'),
        validators: [
          {
            validate: 'required',
            error: t('pages.products.importer.validation.revenue_account.error')
          }
        ]
      },
      {
        key: 'tax',
        type: 'select',
        options: createOptions(props.resources?.taxes),
        label: t('pages.product_groups.all.headers.tax'),
        validators: [
          {
            validate: 'required',
            error: t('pages.products.importer.validation.tax.error')
          }
        ]
      },
      {
        key: 'images',
        sizeHint: 2,
        label: t('pages.product_groups.all.headers.images'),
        validators: [
          {
            validate: 'regex_matches',
            regex: regexURLImage
          }
        ]
      }
    ])

    const importer = computed(
      () =>
        new FlatfileImporter(FLATFILE_LICENSE_KEY, {
          fields: fields.value,
          type: t('pages.customers.title'),
          allowInvalidSubmit: false,
          managed: true,
          disableManualInput: true,
          devMode: process.env.NODE_ENV !== 'production',
          styleOverrides: {
            primaryButtonColor: '#279ff6',
            invertedButtonColor: '#7bbaf3'
          },
          i18nOverrides: {
            de: {
              otherLocales: ['de-DE'],
              overrides: i18nOverrides({
                header: tm('pages.product_groups.importer.flatfile.header'),
                header2: tm('pages.product_groups.importer.flatfile.header2')
              })
            },
            en: {
              otherLocales: ['en-US', 'en-GB'],
              overrides: i18nOverrides({
                header: tm('pages.product_groups.importer.flatfile.header'),
                header2: tm('pages.product_groups.importer.flatfile.header2')
              })
            }
          }
        })
    )

    return { importer }
  },
  computed: {
    ...mapState({
      userId: (state) => state.Auth.user || '-',
      orgName: (state) => state.Auth.orgName || '-'
    }),
    existingProductGroupsIds() {
      return this.resources?.productGroups?.map((pg) => pg.product_group_id)
    }
  },
  watch: {
    resources: {
      immediate: true,
      handler: 'initFlatFileImport'
    }
  },
  methods: {
    initFlatFileImport() {
      this.importer.setCustomer({ userId: this.userId, name: this.orgName })
      this.importer.registerRecordHook(this.validateRecords)
      this.importer.registerFieldHook('product_group_id', this.validateId)
    },
    async handleImport() {
      try {
        const results = await this.importer.requestDataFromUser()
        this.importer.displayLoader()
        await this.createProductGroups(results.data)
        this.importer.displaySuccess(
          this.$t('pages.product_groups.importer.messages.success')
        )
        this.$emit('refresh')
      } catch (err) {
        // Flatfile throws undefined error when user closes the importer by clicking X button.
        if (err) {
          this.$logException(err)
          this.importer.displayError(
            this.$t('pages.product_groups.importer.messages.error')
          )
        }
      }
    },
    async createProductGroups(productGroups) {
      const limit = pLimit(10)
      const inst = th.productGroups()
      const ops = this.normalizeProductGroups(productGroups)
        .map((productGroup) => () => inst.create(productGroup, {}))
        .map((fn) => async () => {
          try {
            await pRetry(fn, {
              retries: 3,
              minTimeout: 100,
              maxTimeout: 1000,
              randomize: true
            })
          } catch (err) {
            this.$logException(err)
          }
        })
        .map((fn) => limit(fn))

      try {
        await Promise.allSettled(ops)
      } catch (err) {
        this.$logException(err)
      }
    },
    normalizeProductGroups(productGroups) {
      return productGroups.map((productGroup) => {
        // Setting the images if exists
        if (productGroup.images)
          productGroup.images = { '1x': productGroup.images }
        return replaceEmptyPropsWithNull(productGroup)
      })
    },
    isProductGroupIdUnique(pgId) {
      return !this.existingProductGroupsIds.includes(pgId)
    },
    validateRecords(record, index, mode) {
      let out = {}
      // NOTE: for performance it's best to use registerRecordHook only for user changes and not for initial parse, for that use registerFieldHook
      if (mode !== 'change') return out
      if (
        record.product_group_id &&
        !this.isProductGroupIdUnique(record.product_group_id)
      ) {
        out.product_group_id = {
          info: [
            {
              message: this.$t(
                'pages.product_groups.importer.validation.product_group_id.error'
              ),
              level: 'error'
            }
          ]
        }
      }
      if (!record.tax) {
        out.tax = {
          info: [
            {
              message: this.$t('pages.products.importer.validation.tax.error'),
              level: 'error'
            }
          ]
        }
      }
      if (!record.account) {
        out.account = {
          info: [
            {
              message: this.$t(
                'pages.products.importer.validation.revenue_account.error'
              ),
              level: 'error'
            }
          ]
        }
      }
      return out
    },
    validateId(productGroupIds) {
      return productGroupIds.map(([pgId, index]) => {
        const info =
          pgId && !this.isProductGroupIdUnique(pgId)
            ? {
                message: this.$t(
                  'pages.product_groups.importer.validation.product_group_id.error'
                ),
                level: 'error'
              }
            : {}

        return [
          {
            info: [info]
          },
          index
        ]
      })
    }
  }
}
</script>
